<template>
  <div forgot-progress>
    <ValidationObserver tag="div" class="field-holder" ref="validator" v-slot="{ invalid }">
      <div v-if="isWithdrawalConfirm" class="content">
        <FpTitle :title="structure.title" :back="structure.back" :step="step" @change-step="changeStep" />
        <p class="desc" v-if="desc" v-html="desc" />
        <p class="extra-desc" v-if="structure.extraDesc && isVerifyRoute" v-html="structure.extraDesc" />
        <component class="fp-component" v-if="modelInfo" :is="structure.template" :step="step" :model-info="modelInfo" :structure="structure" :error-msg="structure.errorMsg" @timer-complete="timerComplete" @clear-error="clearError" @submit="submit" />
      </div>
      <FpFreezeReady v-else @continue="withdrawalReady" />
      <div v-if="isWithdrawalConfirm" class="bottom">
        <ProgressButton v-if="structure.showButton" :button-name="structure.btnName" :progress="isProgress" @click="submit" :disabled="invalid || structure.error" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import siteModel, { routeNames } from '@/constants/model/forgot-password';
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass, isTest } from '@/utils/baseUtil';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FpTitle from '@/views/components/pages/forgot-password/FpTitle.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import FpFreezeReady from '@/views/components/pages/forgot-password/template/FpFreezeReady.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'ForgotProgress',
  mixins: [fpErrorHandler],
  components: { FpFreezeReady, CheckboxItem, FpTitle, ProgressButton, FontIcon },
  props: {
    structure: Specific,
  },
  data() {
    return {
      modelInfo: Specific,
      isProgress: false,
      isLoad: false,
      captcha: null,
      step: 0,
      isInit: false,
      isWithdrawalReady: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    mobile: state('browser', 'mobile'),
    userInfo: state('user', 'userInfo'),
    baseLang: state('env', 'baseLang'),
    brand: state('env', 'brand'),
    brandName : state('env', 'brandName'),
    siteModel() {
      return siteModel();
    },
    indexModel() {
      return {
        0: routeNames.FpInfo,
        1: routeNames.FpSelect,
        2: routeNames.FpVerify,
        3: routeNames.FpReset
      };
    },
    desc() {
      const desc = this.structure.desc;
      if (this.isVerifyRoute) {
        const type = this.isMobile ? this.$t('mobileNumber') : this.$t('emailAddress');
        const method = this.isMobile ? `${this.modelInfo?.CountryCode}${this.modelInfo?.MobileNumber}` : this.modelInfo?.Email;
        const maskedValue = this.modelInfo?.ValueMasked;
        return this.isGgpass ? `${this.$t(desc, { length: '6', type, method })} ` : `${this.$t(desc, { length: '6', type, method: maskedValue })} `;
      }
      const brand = this.isGgpass ? 'GGPass' : this.brandName
      return /** @type { String }*/this.$t(desc, { brand, length: '6', type: this.isMobile ? this.$t('mobileNumber') : this.$t('emailAddress'), method: this.isMobile ? this.$t('mobileNumber') : this.$t('emailAddress') });
    },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    isMobile() {
      if (!this.modelInfo?.LoginMethod) return false;
      return this.modelInfo.LoginMethod === 'Mobile';
    },
    isVerifyRoute() {
      return this.$route.name === 'FpVerify';
    },
    isTest() {
      return isTest();
    },
    isWithdrawal() {
      return [siteIds.GGPOK, siteIds.EVPUKE, siteIds.GGPUKE].includes(this.site);
    },
    isWithdrawalConfirm() {
      if (!this.isWithdrawal) return true;
      return this.isWithdrawal && this.isWithdrawalReady;
    },
    sameStep() {
      return this.step === this.$fp.currentStep;
    },
    currentComponent() {
      return this.structure.template;
    },
    notPassedErrorCodes(){
      return [apiErrorCode.INVALID_PASSWORD_PARTS_EMAIL, apiErrorCode.INVALID_PASSWORD_PARTS_NAME, apiErrorCode.INVALID_PASSWORD_PARTS_MOBILENUMBER, apiErrorCode.INVALID_PASSWORD_LENGTH, apiErrorCode.INVALID_PASSWORD_LETTER, apiErrorCode.PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME, apiErrorCode.INVALID_PASSWORD_PARTS_USERNAME];
    }
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      if (!this.$fp.useCaptcha) return;
      const action = this.$fp.loadCaptchaAction(this.modelInfo.NpApiType);
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(action);
    },
    async hasError(r) {
      if (r.error) {
        if (r.key === 'limit') {
          if (this.$route.name === 'FpInfo' || this.$route.name === 'FpSelect') {
            this.structure.limitTime = +r.value;
            await this.replaceRouteName(this.$fp.nextRoute);
            await this.update();
            return true;
          }
        }

        this.apiErrorHandler(r);
        this.structure.error = true;
        this.structure.success = !this.error.error;
        this.structure.errorMsg = this.error.errorMsg;
        this.structure.errorCode = r.key;
        this.step--;
        if (this.$route.name === 'FpReset') {
          if(this.notPassedErrorCodes.includes(r.key)){
            return true;
          }else {
            await this.replaceRouteName(this.$fp.nextRoute);
            await this.update();
          }
        }
        return true;
      }
      return false;
    },

    setSiteInfo(r) {
      if (r?.SiteId) {
        let siteId = this.convertSiteId(r?.SiteId);
        this.$store.commit('env/setSite', siteId);
        const envEmailKey = `VUE_APP_CS_EMAIL_${siteId}`;
        const csEmail = process.env[envEmailKey] || '';
        this.$store.commit('env/setCsEmail', csEmail);
      }

      if (r?.VerificationMethods) {
        this.modelInfo.VerificationMethods = r.VerificationMethods;
      } else if (r?.accessToken) {
        this.$store.commit('user/setGpToken', r.accessToken);
      } else if (r?.token) {
        this.modelInfo.Token = r.token;
      }
    },

    convertSiteId(site){
      const siteId = site.toUpperCase();
      switch(siteId){
        case siteIds.SEVENXL : return siteIds.SEVENXL;
        case siteIds.NATURAL8 : return siteIds.NATURAL8;
        case siteIds.N8IN : return siteIds.N8IN;
        default : return site;
      }
    },

    async setNextRoute() {
      const nextRouteModel = this.siteModel[this.$route.name].nextRoute;
      let nextRoute = this.isGgpass ? nextRouteModel?.OP : nextRouteModel?.NP;

      if (nextRoute !== null) {
        if (nextRoute === this.$route.name) {
          const next = this.siteModel[nextRoute]?.nextRoute.NP;
          if (next) {
            await this.replaceRouteName(next);
            await this.update();
          }

        } else {
          await this.replaceRouteName(nextRoute);
          await this.update();
        }
      } else {
        await this.replaceRouteName('FpInfo');
        await this.update();

      }
    },
    async submit(token) {
      this.isProgress = true;
      await this.loadCaptcha();
      this.structure.resendInitTime = 0;
      const payload = { ...this.modelInfo, ...this.captcha, VerificationCodeType: 'RESET_PASSWORD' };
      const result = await this.$fp.createAction(payload, token);


      this.isProgress = false;
      if (this.$route.name === 'FpInfo' && this.isGgpass) {
        this.step = 2;
      } else {
        this.step++;
      }

      if (await this.hasError(result)) return;

      this.structure.isPasswordProcess = true;
      this.structure.success = true;

      this.setSiteInfo(result);
      await this.setNextRoute();
    },

    timerComplete() {
      this.clearError();
    },
    async update() {
      const routeName = this.indexModel[this.step];
      if (!routeName) return;
      // const routeName = name || this.$route.name;
      this.$fp.changeRouteName(routeName);
      // const now = this.siteModel[routeName];
      const now = this.$fp.currentPageModel;
      // console.log('now', now);
      await sleep(60);
      // if (this.step !== now.step) {
      //   this.replaceRouteName('FpInfo', this.$route.params, this.$route.query);
      //   return;
      // }
      this.structure.title = now.title;
      this.structure.useCaptcha = now.useCaptcha;
      this.structure.extraDesc = null;
      this.structure.subTitle = now.subTitle;
//      this.structure.desc = now.desc;
      this.structure.btnName = now.btnName;
      this.structure.template = now.component;
      this.structure.back = now.back;

      this.clearError();
    },
    clearError() {
      this.structure.errorMsg = null;
      this.structure.error = false;
    },
    changeStep(step) {
      if(this.isGgpass && this.$route.name === routeNames.FpVerify){
          --step;
      }

      this.step = step;
      const prevRoute = this.$fp.prevRoute;
      if (prevRoute) {
        this.replaceRouteName(prevRoute);
        this.update();
      }

    },
    withdrawalReady() {
      this.isWithdrawalReady = true;
    }
  },
  async mounted() {
    // if (this.isMBP) {
    //   this.replaceRouteName('FpNotSupportSite');
    //   return;
    // }
    if(this.site.toUpperCase() === siteIds.SEVENXL) {
      this.$store.commit('env/setSite', siteIds.SEVENXL);
      await sleep(60);
    }
    if (this.step === 0 && this.$route.name !== 'FpInfo') {
      await this.replaceRouteName('FpInfo', this.$route.params, this.$route.query);
      this.modelInfo = this.$fp.model;
      this.update();
      this.isInit = true;
      return;
    }
    this.modelInfo = this.$fp.model;
    if (!this.isGgpass) {
      this.modelInfo.CountryCode = this.site === siteIds.TWOACE ? 'KR' : 'BD';
    }
    this.update();
    this.isInit = true;
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[forgot-progress] {.flex(); .flex-dc(); .rel(); .h(100%); flex: 1; .pt(30);
  .field-holder {flex: 1; .flex(); .flex-dc(); .gap(30);}
  .fp-component {.pt(40);}
  .content {flex: 1; .flex(); .flex-dc();
    .desc {.p(0); .m(0); .mt(20);
      > em {.c(#fff)}
    }
    .extra-desc {.c(#7F7F7F); .fs(18);}

  }
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
  .bottom { .w(100%); .tc(); .c(#ccc); .mb(80);
    .recaptcha {.flex(); .justify-center(); .p(20);
      > span > em {.fs(16);}
    }
    [progress-button] {.max-w(100%);}
  }

}
</style>